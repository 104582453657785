<template>
  <div>
    <el-button type="primary" :loading="loading" @click="handleClick"> Upload DDEX</el-button>
  </div>
</template>

<script>
import { ElMessage } from "element-plus";

export default {
  name: "SubmitButton",
  props: {
    id: String,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async handleClick() {
      this.loading = true;
      try {
  
        const res = await this.$axios.get(
          `https://cc.raveon.ru/create_ddex?release_id=${this.$props.id}`
        );
        if (res.status === 200) {
          ElMessage({
            message: "Запрос успешно выполнен!",
            type: "success",
          });
          return;
        }
      } catch (error) {
        ElMessage({
          message: "Ошибка при выполнении запроса.",
          type: "error",
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
